import React from 'react';
import { ProjectList } from "../../../data/ProjectData";
import {
  Card,
  CardRight,
  TechCardContainer,
  TechCard,
  BtnGroup,
} from "./ProjectCardElements";
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Assurez-vous d'avoir importé Bootstrap CSS
import './ProjectCard.css'; // Assurez-vous que le chemin est correct

function ProjectCard({ selectedCategory }) { // Utilisez selectedCategory comme prop
  const navigate = useNavigate(); // Initialize the useNavigate hook


  const handleProjectClick = (project) => {
    navigate(`/project/projectId:${project.id}`, {
      state: {
        title: project.title,
        images: project.images,
        descriptions: project.descriptions,
        conclusion: project.conclusion,
        is_video: project.is_video,
        video_link: project.video_link,
      },
    });
  };

  // Filtrer les projets en fonction de la catégorie
  const filteredProjects = selectedCategory
    ? ProjectList.filter(project => project.category === selectedCategory)
    : ProjectList;

  return (
    <>
      {filteredProjects.length > 0 ? (
        filteredProjects.map((list, index) => (
          <Card key={index}>
            {list.isCarousel ? (
              <Carousel interval={null}>
                {list.carousel_images.map((image, idx) => (
                  <Carousel.Item key={idx}>
                    <img
                      src={image}
                      alt={`carousel-${idx}`}
                      className="d-block w-100 carousel-img"
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : (
              <img
                src={list.img}
                alt={list.name}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            )}
            <CardRight>
              <h4 style={{ whiteSpace: "pre-line" }}>{list.title}</h4>
              <p style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: list.description }}></p>
              <TechCardContainer>
                {list.tech_stack.map((tech, techIndex) => (
                  <TechCard key={techIndex}>{tech}</TechCard>
                ))}
              </TechCardContainer>
              <BtnGroup>
                {list.github_url.length > 0 && (
                  <a
                    className="btn SecondaryBtn btn-shadow"
                    href={list.github_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Github ➜
                  </a>
                )}
                {list.demo_url.length > 0 && (
                  <a
                    className="btn PrimaryBtn btn-shadow"
                    href={list.demo_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visit ➜
                  </a>
                )}
                {list.project_demo.length > 0 && (
                  <button
                    className="btn PrimaryBtn btn-shadow"
                    onClick={() => handleProjectClick(list)}
                  >
                    Project Details Page ➜
                  </button>
                )}
              </BtnGroup>
            </CardRight>
          </Card>
        ))
      ) : (
        <p>No projects found for the selected category.</p>
      )}
    </>
  );
}

export default ProjectCard;
