import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"; // Importer le loader GLTF
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { TextureLoader } from "three"; // Importer le loader de textures

const ThreeModel = () => {
  const containerRef = useRef(null); // Référence pour attacher le canvas 3D

  useEffect(() => {
    // Chemin du modèle glTF dans le dossier public
    const modelPath = '/models/game/scene.gltf'; // Le chemin relatif vers le modèle glTF dans public
    const texturePath = '/models/game/textures/'; // Le dossier des textures

    // Initialisation de la scène
    const scene = new THREE.Scene();

    // Ajouter une couleur de fond douce à la scène
    scene.background = new THREE.Color(0x87ceeb); // Couleur ciel (bleu clair)

    // Caméra avec un meilleur angle de vue
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.set(-25, 10, 30);

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true; // Activer l'ombrage

    // Ajouter le rendu au DOM
    containerRef.current.appendChild(renderer.domElement);

    // Ajouter une lumière directionnelle pour l'éclairage principal
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(5, 5, 5).normalize();
    directionalLight.castShadow = true; // Activer l'ombre pour cette lumière
    scene.add(directionalLight);

    // Ajouter une lumière ambiante douce
    const ambientLight = new THREE.AmbientLight(0x404040, 0.5); // Lumière d'ambiance faible
    scene.add(ambientLight);

    // Ajouter une lumière de remplissage plus douce pour adoucir les ombres
    const fillLight = new THREE.DirectionalLight(0xffffff, 0.3);
    fillLight.position.set(-5, -5, -5).normalize();
    scene.add(fillLight);

    // Ajouter un ciel (skybox) pour un effet de fond plus réaliste
    const loader = new THREE.CubeTextureLoader();

    const skyboxUrls = [
      '/models/skybox/xpos.png',  // +X (droite)
      '/models/skybox/xneg.png',  // -X (gauche)
      '/models/skybox/ypos.png',  // +Y (haut)
      '/models/skybox/yneg.png',  // -Y (bas)
      '/models/skybox/zpos.png',  // +Z (avant)
      '/models/skybox/zneg.png',  // -Z (arrière)
    ];

    // Vérification des chemins des images de la skybox
    skyboxUrls.forEach((url) => {
      const img = new Image();
      img.onload = () => {
        console.log(`Image loaded successfully: ${url}`);
      };
      img.onerror = () => {
        console.error(`Failed to load image: ${url}`);
      };
      img.src = url;
    });

    // Charger la skybox
    loader.load(
      skyboxUrls,
      (loadedTexture) => {
        scene.background = loadedTexture; // Appliquer la texture de la skybox en fond
        console.log("Skybox loaded successfully.");
      },
      undefined, // Pas besoin de gestion intermédiaire
      (error) => {
        console.error("Error loading skybox: ", error);
      }
    );

    // Contrôles de la caméra
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.screenSpacePanning = false;
    controls.maxPolarAngle = Math.PI / 2;

    // Charger les textures avec TextureLoader
    const textureLoader = new TextureLoader();
    const diffuseTexture = textureLoader.load(`${texturePath}texture_diffuse.jpg`);

    // Charger le modèle glTF
    const gltfLoader = new GLTFLoader();
    let object;
    gltfLoader.load(modelPath, (gltf) => {
      object = gltf.scene;

      // Appliquer les textures au modèle si nécessaire
      object.traverse((child) => {
        if (child.isMesh) {
          child.material.map = diffuseTexture; // Applique la texture diffuse
          child.material.needsUpdate = true;
          child.castShadow = true;  // Lancer des ombres
          child.receiveShadow = true; // Recevoir des ombres
        }
      });

      // Ajouter le modèle à la scène
      scene.add(object);
      object.scale.set(10, 10, 10); // Ajuster la taille du modèle si nécessaire
      object.position.y = 0; // Placer légèrement au-dessus du sol
    }, undefined, (error) => {
      console.error(error);
    });

    // Gestion du redimensionnement de la fenêtre
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    // Animer la scène
    const animate = () => {
      requestAnimationFrame(animate);

      // Si le modèle est chargé, appliquer la rotation continue
      if (object) {
        object.rotation.y += 0.01; // Rotation autour de l'axe Y
      }

      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    // Nettoyage lors du démontage du composant
    return () => {
      window.removeEventListener('resize', handleResize);
      renderer.dispose();
    };

  }, []); // Le tableau vide assure que l'effet ne s'exécute qu'une seule fois

  return (
    <div
      ref={containerRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1 // Pour garder le modèle en arrière-plan
      }}
    ></div>
  );
};

export default ThreeModel;
