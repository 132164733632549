import React, { useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import Header from "../Header/Header";
import {
  HeroContainer,
  HeroWrapper,
  HeroLeft,
  HeroRight,
  Image,
  ScrollDown,
  ScrollLink,
} from "./HeroElements";
import { TypeAnimation } from 'react-type-animation';
import ScrollAnimation from "react-animate-on-scroll";


import './Hero.scss';

function Hero() {
  const [isOpen, setIsOpen] = useState(false);
  const [showSubtitle, setShowSubtitle] = useState(false);
  const [showScrollDown, setShowScrollDown] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <main>
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <Header toggle={toggle} />
      <HeroContainer>
        <HeroWrapper>
          <HeroLeft>
            <div className="logo"><b>Hi, I'm William Chapron</b></div>
            <ScrollAnimation animateIn="fadeIn" >
              <TypeAnimation
                cursor={false}
                sequence={[
                  '',
                  () => setShowSubtitle(true)
                ]}
                speed={{ type: "keyStrokeDelayInMs", value: 50 }}
                wrapper="h1"
                repeat={0}
              />



              {showSubtitle &&
                <TypeAnimation
                  cursor={true}
                  sequence={[
                    "A passionate 3D Programmer from France",
                    1000,
                    "I’m currently learning 3D PROGRAMMING, I aspire to become a 3D engine programmer.",
                    1400,
                    "I also create Desktop and Web Applications with .NET Framework, React/Express js, Electron",
                    1100,
                    "Don't hesitate to contact me at williamchapronpro@gmail.com or on LinkedIn.",
                    1000,
                  ]}
                  speed={50}
                  deletionSpeed={80}
                  wrapper="h5"
                  repeat={Infinity}
                />
              }
            </ScrollAnimation>

          </HeroLeft>
          <HeroRight>
            <ScrollAnimation animateIn="fadeIn">
              <Image
                src="/backlogo.png"
                alt="Logo de williamdeveloper"
              />
            </ScrollAnimation>
            
          </HeroRight>
        </HeroWrapper>
        {showScrollDown &&<ScrollAnimation animateIn="flipInX" offset={0}>
        <ScrollDown to="projects" id="scrollDown">
          <ScrollLink>
            Scroll 
            <img
              src="/scroll-down.svg"
              alt="Fond / Background de williamdeveloper home"
            />
          </ScrollLink>
        </ScrollDown>
        </ScrollAnimation>}
      </HeroContainer>
    </main>
  );
}

export default Hero;
