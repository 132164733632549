import React, { useState } from "react";
import ProjectCard from "./ProjectCard/ProjectCard";
import './Projects.scss'; // Importation du fichier CSS

function Projects() {
  const [selectedCategory, setSelectedCategory] = useState('Prog');

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const projects = [
    {
      id: 1,
      title: '3D Programming',
      description: '3D software, physics engine/simulation, graphics rendering',
      category: 'Prog'
    },
    {
      id: 2,
      title: 'Game Dev',
      description: 'video games and Immersive applications ',
      category: 'GameDev'
      // Unity/Unreal
    },
    {
      id: 3,
      title: 'Web & Desktop Applications',
      description: 'Custom website, Backend API, Database, Desktop Applications',
      category: 'ProgWeb'
      // Custom web development projects using ReactJs, Restful APIs with ExpressJs, ORM integration, SQL/NoSQL databases and UI libraries.
    },
    {
      id: 4,
      title: 'Mathematics & Physics',
      description: 'Mathematical & Physics modeling and algorithms.',
      category: 'Math'
    },
    {
      id: 5,
      title: 'Algorithm Training',
      description: 'Training on algorithms and data structures in C and C++, featuring theory and LeetCode exercises to boost problem-solving skills for technical interviews.',
      category: 'Algorithm'
    },
  ];

  const categories = [
    { id: 'Prog', label: '3D Programming' },
    { id: 'GameDev', label: 'Game Dev' },
    { id: 'ProgWeb', label: 'Web & Desktop Applications' },
    { id: 'Math', label: 'Mathematics & Physics' },
    { id: 'Algorithm', label: 'C/C++ Algorithm Training' }, 
  ];

  const getSectionTitle = () => {
    const project = projects.find(p => p.category === selectedCategory);
    return project ? project.description : 'Description non trouvée';
  };

  return (
    <>
      <svg
        height="100%"
        width="100%"
        id="svg"
        viewBox="0 0 1440 400"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 0,400 C 0,400 0,200 0,200 C 114.35714285714289,156.53571428571428 228.71428571428578,113.07142857142858 351,131 C 473.2857142857142,148.92857142857142 603.4999999999998,228.25 713,248 C 822.5000000000002,267.75 911.2857142857144,227.92857142857142 1029,210 C 1146.7142857142856,192.07142857142858 1293.3571428571427,196.03571428571428 1440,200 C 1440,200 1440,400 1440,400 Z"
          stroke="none"
          strokeWidth="0"
          fill="#151418ff"
          transform="rotate(-180 720 200)"
        ></path>
      </svg>
      <div className="ProjectWrapper" id="projects">
        <div className="Container">
          <div className="category-banner">
            {categories.map(category => (
              <button
                key={category.id}
                className={`category-button`}
                onClick={() => handleCategoryChange(category.id)}
              >
                {category.label}
              </button>
            ))}
          </div>
          <div className="SectionTitle">{getSectionTitle()}</div>
          <ProjectCard selectedCategory={selectedCategory} />
        </div>
      </div>
    </>
  );
}

export default Projects;
